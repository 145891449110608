import style from "./css/App.css";
import App from "../lib/App";
import axios from "axios";
import { isMobile } from "../lib/utils";
import { request } from "../lib/utils/axios";

const isDemoPage = window.location.pathname === "/demo",
  isMobilePage =
    window.location.pathname === "/mob" || window.location.pathname === "/qr",
  isQrPage = window.location.pathname === "/mob",
  isInvitationPage = window.location.pathname === "/i",
  { API_ROOT_URI, FTX_API_KEY } = process.env;

const setError = (
  error,
  timer = 5000,
  defaultErrorMessage = "Something went wrong. Please try again later."
) => {
  let errorDiv = document.querySelector("div#identity-alert__error");

  if (!errorDiv) return;

  let errorMessage = errorDiv.querySelector("#identity-alert__message"),
    errorDisplay = error;

  // if (typeof error === "object" && error !== null) {
  //   errorDisplay = getErrorHtmlFromResponse(error) || defaultErrorMessage;
  // }

  if (!errorMessage) return;

  if (errorDisplay) {
    errorDiv.style.display = "flex";
    errorDiv.style.opacity = 1;
    errorMessage.innerHTML = errorDisplay;

    setTimeout(() => {
      errorDiv.style.display = "none";
      errorDiv.style.opacity = 0;
      errorMessage.innerHTML = "";
    }, timer);
  } else {
    errorDiv.style.display = "none";
    errorDiv.style.opacity = 0;
    errorMessage.innerHTML = "";
  }
};

const initSession = (personalInfo) => {
  let reqData = {
    collectPersonalInfo: true,
  };

  if (personalInfo && false) {
    reqData = {
      collectPersonalInfo: false,
      personalInfo,
    };
  }

  if (personalInfo)
    reqData[
      "redirectUri"
    ] = `${window.location.origin}${window.location.pathname}`;

  return axios.post(`${API_ROOT_URI}/Init`, reqData, {
    headers: {
      FTxIdentityApiKey: FTX_API_KEY,
    },
  });
};

let params = new URLSearchParams(window.location.search),
  sessionId = params.get("sessionId"),
  profileToken = params.get("profileToken"),
  token = params.get("t");

if (!isDemoPage && !isMobilePage) {
  if (!sessionId) {
    sessionId = window.localStorage.getItem(`FTXAgeVerifySessionId`);

    if (sessionId) {
      window.localStorage.removeItem("FTXAgeVerifySessionId");
      params.set("sessionId", sessionId);
      window.location.search = params.toString();
    }
  }
  if (sessionId || profileToken) {
    const libInstance = new App({
      fullScreen: true,
      sessionId,
      profileToken,
      onError: setError,
    });
    libInstance.start();
  }
}

if (isMobilePage && isMobile()) {
  try {
    let res = await request.get(`/mobile/${token}`);
    if (res && res.data && res.data.data) {
      if (isQrPage) window.location.href = `/?sessionId=${res.data.data}`;
      else {
        let response = await request.put(`mobile/${res.data.data}`);
        if (response && response.data && response.data.data)
          window.location.href = `/?sessionId=${res.data.data}`;
      }
    }
  } catch (e) {
    setError(e);
  }
}

if(isInvitationPage){
  try{
    let res = await request.post(`/init/invite/${token}`);
    if(res && res.data && res.data.data){
      window.location.href = `/?sessionId=${res.data.data.sessionId}`
    }
  }
  catch(e){
    setError(e);
  }
}

let rootContainer = document.getElementById("root");

if (rootContainer) {
  rootContainer.innerHTML = `<div class="identity" style="align-items: center; display: flex; font-family: DM Sans,sans-serif; font-size: 16px; height: 100vh; justify-content: center; text-align: center">
                                                  <div id="identity__svg">
                                                      <svg style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                          <defs>
                                                              <symbol id="svg__upload" viewBox="0 0 24 18"><path d="M11.49,7.17l-2.5,3.06-.74-.68,3.74-4.56,3.75,4.59-.75,.66-2.5-3.08v7.83h-1V7.17Zm7.01,9.83H5.5c-2.48,0-4.5-2.02-4.5-4.5,0-2.18,1.55-4.04,3.7-4.42l.78-.14,.04-.79c.18-3.45,3.03-6.15,6.48-6.15s6.3,2.7,6.48,6.15l.04,.79,.78,.14c2.14,.39,3.7,2.25,3.7,4.42,0,2.48-2.02,4.5-4.5,4.5m.98-9.91c-.21-3.95-3.47-7.09-7.48-7.09S4.73,3.14,4.52,7.09C1.95,7.56,0,9.8,0,12.5c0,3.04,2.46,5.5,5.5,5.5h13c3.04,0,5.5-2.46,5.5-5.5,0-2.7-1.95-4.94-4.52-5.41" fill="currentColor" style="fill-rule:evenodd;"/></symbol>
                                                              <symbol id="svg__camera" viewBox="0 0 24 20"><path d="M17,0l1.41,2.11c.37,.56,1,.89,1.66,.89h3.93V20H0V3H5.93c.67,0,1.29-.33,1.66-.89L9,0h8Zm3.07,4c-1.01,0-1.94-.5-2.5-1.34l-1.11-1.66h-6.93l-1.11,1.66c-.56,.84-1.49,1.34-2.5,1.34H1v15H23V4h-2.93Zm-7.05,1c3.31,0,6,2.69,6,6s-2.69,6-6,6-6-2.69-6-6,2.69-6,6-6Zm0,1c2.76,0,5,2.24,5,5s-2.24,5-5,5-5-2.24-5-5,2.24-5,5-5Zm0,2c1.66,0,3,1.34,3,3s-1.34,3-3,3-3-1.34-3-3,1.34-3,3-3Zm0,1c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2ZM5.02,7c0-.55-.45-1-1-1s-1,.45-1,1,.45,1,1,1,1-.45,1-1ZM2.02,1h3v1H2.02V1Z" fill="currentColor" style="fill-rule:evenodd;"/></symbol>
                                                              <symbol id="svg__success" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#75be43;"/><path d="M217.47,341.62c4.55,4.55,12.34,4.55,16.89,0l138.35-137.7c4.55-4.55,4.55-12.34,0-16.89l-16.89-16.89c-4.55-4.55-12.34-4.55-16.89,0l-113.02,112.37-52.61-52.61c-4.55-4.55-12.34-4.55-16.89,0l-16.89,16.89c-4.55,4.55-4.55,12.34,0,16.89l77.94,77.94h0Z" style="fill:#fff;"/></symbol>
                                                              <symbol id="svg__error" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:currentColor;"/><path d="M346,324.25l-69-68.25,68.25-69-21-21-68.25,69-69-68.25-21,21,69,68.25-68.25,69,21,21,68.25-69,69,68.25,21-21h0Z" style="fill:#fff;"/></symbol>
                                                              <symbol id="svg__alert-success" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#75be43; opacity:.8;"/><path d="M221.49,333.12c4.2,4.2,11.02,4.2,15.22,0l123.78-123.78c4.2-4.2,4.2-11.02,0-15.22l-15.22-15.22c-4.2-4.2-11.02-4.2-15.22,0l-100.95,101.01-47.15-47.19c-4.2-4.2-11.02-4.2-15.22,0l-15.22,15.22c-4.2,4.2-4.2,11.02,0,15.22l69.98,69.96Z" style="fill:#03101b;"/></symbol>
                                                              <symbol id="svg__alert-error" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#f65f5f; opacity:.8;"/><path d="M355.71,331.42l-76.31-75.58,75.56-76.22-23.53-23.33-75.54,76.27-76.24-75.52-23.35,23.35,76.31,75.66-75.56,76.29,23.35,23.35,75.78-76.37,76.2,75.62,23.33-23.53Z" style="fill:#03101b;"/></symbol>
                                                              <symbol id="svg__alert-warning" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#f4c050; opacity:.8;"/><path d="M234.88,139.81v147.88h42.25V139.81h-42.25Zm0,232.38h42.25v-42.25h-42.25v42.25Z" style="fill:#03101b; fill-rule:evenodd;"/></symbol>
                                                              <symbol id="svg__alert-info" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#0075ff; opacity:.8;"/><path d="M235.01,371.42V224.52h41.97v146.9h-41.97Zm0-230.84h41.97v41.97h-41.97v-41.97Z" style="fill:#03101b; fill-rule:evenodd;"/></symbol>
                                                              <symbol id="svg__pencil" viewBox="0 0 24 24"><path fill="currentColor" d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"/></symbol>
                                                              <symbol id="svg__loader" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;"  viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                      <circle cx="40" cy="40" r="0" fill="none" stroke="currentColor" stroke-width="8">
                                                                      <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
                                                                      <animate attributeName="opacity" repeatCount="indefinite" dur="1.5384615384615383s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
                                                                      </circle><circle cx="40" cy="40" r="0" fill="none" stroke="currentColor" stroke-width="8">
                                                                      <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.7692307692307692s"></animate>
                                                                      <animate attributeName="opacity" repeatCount="indefinite" dur="1.5384615384615383s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.7692307692307692s"></animate>
                                                                      </circle>
                                                              </symbol>
                                                          </defs>
                                                      </svg>
                                                  </div>
                                                  <div class="identity__section">
                                                        <div class="identity__row">
                                                          <div class="identity__col-12">
                                                            <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                                                <button data-close="close" style="display:none;" type="button">&times;</button>
                                                                <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                                                <div data-text="text" id="identity-alert__message"></div>
                                                            </div>
                                                          </div>
                                                      </div>
                                                      <img src="logo.svg" />
                                                      <h1>Age Verification</h1>
                                                      <p style="width: 320px; height: 48px;">Please verify your account information to continue Age Verification.</p>
                                                      <div id="btn-verify"></div>
                                                  </div>
                                                  </div>`;

  let styleElement = document.createElement("style");
  styleElement.textContent = style;

  rootContainer.appendChild(styleElement);
}

if (isMobilePage) {
  let isMobileDevice = isMobile();
  if (!isMobileDevice) {
    setError("Please continue from mobile device.");
  }
}

if (isDemoPage && process.env.NODE_ENV !== "prod") {
  var container = document.getElementById("btn-verify"),
    verifyAgeBtn = document.createElement("button"),
    verifyAgeRedirect = document.createElement("button");

  verifyAgeBtn.innerText = "Verify Age";
  verifyAgeBtn.className = "next btn btn-blue";

  verifyAgeRedirect.innerText = "Verify Age Redirect";
  verifyAgeRedirect.className = "next btn btn-gray";

  verifyAgeBtn.onclick = (event) => {
    if (event.target.classList.contains("spinning")) return;

    event.target.classList.add("spinning");

    initSession()
      .then(({ data }) => {
        if (data.data && data.data.sessionId) {
          const libInstance = new App({
            onError: setError,
            sessionId: data.data.sessionId,
          });

          libInstance.start();
        }
      })
      .finally(() => {
        event.target.classList.remove("spinning");
      });
  };

  verifyAgeRedirect.onclick = (event) => {
    if (event.target.classList.contains("spinning")) return;

    event.target.classList.add("spinning");

    initSession({
      firstName: "Christopher",
      lastName: "Pearson",
      dob: "1963-03-08", // "1993-12-27",
      addressLine1: "16 Center ST",
      addressLine2: null,
      city: "ellenville",
      state: "NY",
      zip: "12428",
      email: "mohit.kalvani@ftxinfotech.com",
      phone: "8189750320",
      countryCode: "+1",
      // dropIfFound: true,
      // keepOtp: true,
    })
      .then(({ data }) => {
        if (data.data && data.data.sessionId) {
          window.location.href = `/?sessionId=${data.data.sessionId}`;
        }
      })
      .finally(() => {
        event.target.classList.remove("spinning");
      });
  };

  container.appendChild(verifyAgeBtn);
  container.appendChild(verifyAgeRedirect);
}

export default App;

import FormValidator from "./FormValidator";
import { request } from "../utils/axios";
import { getSessionId, setSessionData, getSessionData, getShadowRoot } from "../utils/store";
import { nextPrev, closeSession } from "../utils/StepService";
import { getDialogState } from "../utils/DialogService";
import { setLoading, setError, isMobile } from "../utils";
import flatpickr from "flatpickr";
import flatpickrsCss from "flatpickr/dist/themes/dark.css";

class UpdatePersonalInfo {
  validator = null;
  datePicker = null;
  personalInfoObj = {};
  allowEdit = false;

  fillInputs = async () => {
    if (!this.personalInfoObj?.firstName) return;
    if (this.datePicker) {
      let dob = this.formatDate(this.personalInfoObj.dob);
      this.datePicker.setDate(dob, "m/d/Y");
    }
    const personalInfoForm = getShadowRoot().querySelector(
      `#updatePersonalInfoContent`
    );
    if (personalInfoForm) {
      personalInfoForm.querySelector("#firstName").value =
        this.personalInfoObj?.firstName || "";
      personalInfoForm.querySelector("#lastName").value =
        this.personalInfoObj?.lastName || "";
    }
  };

  render = async () => {
    const sessionData = getSessionData();
    if (!sessionData?.preFillInformation) await this.getSessionDetail();
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    if (modalBox.querySelector("#updatePersonalInfoContent")) return;

    const flatpickrStyleSheet= document.createElement("style");
    flatpickrStyleSheet.textContent = flatpickrsCss;

    getShadowRoot().appendChild(flatpickrStyleSheet);

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" id="updatePersonalInfoContent" data-form-tab>
        <h3 class="title">Update Personal Info</h3>
        <div class="identity__row">
          <div class="identity__col-12">
            <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
              <button data-close="close" style="display:none;" type="button">&times;</button>
              <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
              <div id="identity-alert__message" data-text="text"></div>
            </div>
          </div>
          <div class="identity__col-6">
            <div class="identity-field floating-field">
              <input name="firstName" id="firstName" type="text" class="identity-input input" placeholder="none" maxlength="50" onkeypress="return /^[a-zA-Z]*$/i.test(event.key)" />
              <label for="firstName" class="identity-floating-label">First Name*</label>
              <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              <span class="error-message"></span>
            </div>
          </div>
          <div class="identity__col-6">
            <div class="identity-field floating-field">
              <input name="lastName" id="lastName" type="text" placeholder="none" class="identity-input input" maxlength="50" onkeypress="return /^[a-zA-Z]*$/i.test(event.key)" />
                <label for="lastName" class="identity-floating-label">Last Name*</label>
              <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
              <span class="error-message"></span>
            </div>
          </div>
          <div class="identity__col-12">
            <div class="identity-field identity-field-addon floating-field identity-field-dob">
              <input name="dob" id="dob" type="text" placeholder="Date of Birth*" class="identity-input" maxlength="10"/>
              <!--<input name="dob" id="dob" type="text" 
              onfocus="(this.type='date')" onblur="if(!this.value) this.type='text'" placeholder="Date of Birth*" class="identity-input" />-->
              <label for="dob" class="identity-floating-label">Date of Birth(MM/DD/YYYY)*</label>
              <svg class="svg identity-addon-icon addon-icon" id="calendarIcon" width="40px" height="40px" aria-hidden="true" role="img"><use href="#svg__calendar" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__calendar"></use></svg>
              
              <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
              <span class="error-message"></span>
            </div>
          </div>
          <div class="identity__col-12">
            <div class="identity-field">
              <button id="btn-continue" type="submit" class="identity-btn btn-continue">Continue
                <span class="loading-icon">
                  <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                    <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>`
    );

    this.bindEvents();
  };

  bindEvents() {
    const personalInfoForm = getShadowRoot().querySelector(
      `#updatePersonalInfoContent`
    );

    personalInfoForm.addEventListener("submit", (event) => {
      event.preventDefault();

      const fields = {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        dob: {
          required: true,
        },
      };

      this.validator = new FormValidator(event.target, fields);

      let validData = this.validator.validate();
      if (validData.isValid) {
        validData.data.dob = validData.data.dob.replace(
          /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/,
          "$3-$1-$2"
        );
        setLoading(true);

        request
          .put(`/Details/${getSessionId()}`, {
            ...validData.data,
          })
          .then(({ data }) => {
            setSessionData({ ...data.data });
            nextPrev(1);
          })
          .catch(({ response }) => {
            if (
              response?.data &&
              response.data?.errors &&
              response?.data?.errors?.REDIRECT_BACK
            ) {
              setSessionData({
                redirectBack: true,
              });
              nextPrev(1);
            } else setError(response);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    const myInput = personalInfoForm.querySelector("#dob");

    myInput.addEventListener('input', function() {
      var inputValue = this.value.replace(/\D/g, ''); // Remove non-numeric characters
      var formattedValue = '';
  
      if (inputValue.length > 0) {
          formattedValue += inputValue.substring(0, 2); // Extract month
          if (inputValue.length >= 3) {
              formattedValue += '/' + inputValue.substring(2, 4); // Add dash and extract day
          }
          if (inputValue.length >= 5) {
              formattedValue += '/' + inputValue.substring(4, 8); // Add dash and extract year
          }
      }
  
      this.value = formattedValue;
  });

    const options = {
      allowInput:"true",
      enableTime: false,
      dateFormat: "m/d/Y",
      disableMobile: "true",
      disable: [
        function (date) {
          // return true to disable
          return new Date() < date;
        },
      ],
      appendTo : getShadowRoot(),
    };
    this.datePicker = flatpickr(myInput, options);

if(isMobile()){
  this.datePicker.calendarContainer.addEventListener('touchstart', function(event) {
    event.stopPropagation();
  });
}
else{
    this.datePicker.calendarContainer.addEventListener('mousedown', function(event) {
      event.stopPropagation();
    });
  }
       const calendarIcon = personalInfoForm.querySelector("#calendarIcon");
    calendarIcon.addEventListener("click", function (e) {
      personalInfoForm.querySelector("#dob").focus();
    });

    const sessionData = getSessionData();
    if (
      sessionData?.preFillInformation &&
      Object.values(sessionData.preFillInformation).length
    ) {
      this.personalInfoObj = sessionData?.preFillInformation;
      this.fillInputs();
    }
  }

  formatDate(inputDate) {
    if (!inputDate) return "";
    var date = new Date(inputDate + "T00:00:00");
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  getSessionDetail = async () => {
    return await request
      .get(`/Details/${getSessionId()}`)
      .then(({ data }) => {
        if (data.data) {
          setSessionData({ ...data.data });
        }
      })
      .catch(() => {
        var dialogState = getDialogState();
        closeSession();
        dialogState.errorCallback();
      });
  };
}

export default UpdatePersonalInfo;

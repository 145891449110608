import FormValidator from "./FormValidator";
import { request } from "../utils/axios";
import {
  getSessionId,
  setSessionData,
  getSessionData,
  getShadowRoot,
  getallowEditing,
} from "../utils/store";
import { closeSession, nextPrev } from "../utils/StepService";
import { setLoading, setError, isMobile } from "../utils";
import flatpickr from "flatpickr";
import flatpickrCss from "flatpickr/dist/themes/dark.css";

class PersonalInfo {
  componentForm = {
    street_number: { id: "addressLine1", code: "short_name" },
    route: { id: "addressLine1", code: "long_name" },
    subpremise: { id: "addressLine2", code: "long_name" },
    locality: { id: "city", code: "long_name" },
    administrative_area_level_1: { id: "state", code: "short_name" },
    // administrative_area_level_2: { id: "addressLine2", code: "long_name" },
    // country: {id: "country", code: "long_name"},
    postal_code: { id: "zip", code: "short_name" },
  };
  autocomplete = {};
  validator = null;
  datePicker = null;
  personalInfoObj = {};
  allowEdit = false;

  fillInputs = async () => {
    const container = getShadowRoot();
    const sessionData = getSessionData();
    this.allowEdit = !!(
      sessionData?.editPersonalInfo === true ||
      sessionData?.profileDetails?.showFromUpdateInfo === true
    );

    if (this.datePicker) {
      let dob = this.formatDate(this.personalInfoObj.dob);
      this.datePicker.setDate(dob, "m/d/Y");
    }
    const personalInfoForm = container.querySelector(`#personalInfoContent`);
    if (personalInfoForm) {
      personalInfoForm.querySelector("h3.title").innerText = this.allowEdit
        ? "Update Details"
        : "Personal Information";
        if(getallowEditing()=== false ){
          if( this.personalInfoObj?.firstName)
            personalInfoForm.querySelector("#firstName").readOnly = true;
          if( this.personalInfoObj?.lastName)
            personalInfoForm.querySelector("#lastName").readOnly = true;
          if( this.personalInfoObj?.addressLine1)
            personalInfoForm.querySelector("#addressLine1").readOnly = true;
          if( this.personalInfoObj?.addressLine2)
            personalInfoForm.querySelector("#addressLine2").readOnly = true;
          if( this.personalInfoObj?.city)
            personalInfoForm.querySelector("#city").readOnly = true;
          if( this.personalInfoObj?.state)
            personalInfoForm.querySelector("#state").readOnly = true;
          if( this.personalInfoObj?.zip)
            personalInfoForm.querySelector("#zip").readOnly = true;
        }
      personalInfoForm.querySelector("#firstName").value =
        this.personalInfoObj?.firstName || "";
      personalInfoForm.querySelector("#lastName").value =
        this.personalInfoObj?.lastName || "";
      personalInfoForm.querySelector("#addressLine1").value =
        this.personalInfoObj?.addressLine1 || "";
      personalInfoForm.querySelector("#addressLine2").value =
        this.personalInfoObj?.addressLine2 || "";
      personalInfoForm.querySelector("#city").value =
        this.personalInfoObj?.city || "";
      personalInfoForm.querySelector("#state").value =
        this.personalInfoObj?.state || "";
      personalInfoForm.querySelector("#zip").value =
        this.personalInfoObj?.zip || "";
      personalInfoForm.querySelector("#phone").value =
        this.personalInfoObj?.phone || "";
        if (this.personalInfoObj?.phone){
          personalInfoForm.querySelector("#phone").readOnly = true;
          personalInfoForm.querySelector("#phone").value= this.personalInfoObj.phone.replace(/\D/g, "")
          .replace(/^0+/, "")
          .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, function (txt, f, s, t) {
            if (t) {
              return `(${f})-${s}-${t}`;
            } else if (s) {
              return `(${f})-${s}`;
            } else if (f.length > 0 && f.length <= 3) {
              return `(${f}`;
            } else if (f) {
              return `(${f})`;
            }
          });
        }
      personalInfoForm.querySelector("#email").value =
        this.personalInfoObj?.email || "";
      if (this.personalInfoObj?.email)
        personalInfoForm.querySelector("#email").readOnly = true;

    }
  };

  onBack = async () => {
    this.personalInfoObj = await this.getProfileDetails();
    this.fillInputs();
    setTimeout(() => setLoading(false), 0);

    if (this.allowEdit)
      setTimeout(() => {
        setError("Please input your details matching with Driver's license.");
      }, 0);
  };

  render = async () => {
    const sessionData = getSessionData();
    if (sessionData?.profileDetails?.showFromUpdateInfo === true) {
      this.personalInfoObj = await this.getProfileDetails();
    }
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#personalInfoContent")) return;

    const flatpickrStyleSheet= document.createElement("style");
    flatpickrStyleSheet.textContent = flatpickrCss;

    getShadowRoot().appendChild(flatpickrStyleSheet);

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" id="personalInfoContent" data-form-tab>
                                <h3 class="title">Personal Information</h3>
                                <div class="identity__row">
                                  <div class="identity__col-12">
                                    <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                      <button data-close="close" style="display:none;" type="button">&times;</button>
                                      <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                      <div id="identity-alert__message" data-text="text"></div>
                                    </div>
                                  </div>
                                <div class="identity__col-6">
                                    <div class="identity-field floating-field">
                                        <input name="firstName" id="firstName" type="text" class="identity-input input" placeholder="none" maxlength="50" onkeypress="return /^[a-zA-Z]*$/i.test(event.key)" />
                                        <label for="firstName" class="identity-floating-label">First Name*</label>
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                             <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                         </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field floating-field">
                                        <input name="lastName" id="lastName" type="text" placeholder="none" class="identity-input input" maxlength="50" onkeypress="return /^[a-zA-Z]*$/i.test(event.key)" />
                                         <label for="lastName" class="identity-floating-label">Last Name*</label>
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity-field identity-field-addon floating-field identity-field-dob">
                                        <input name="dob" id="dob" type="text"  placeholder="Date of Birth*" class="identity-input" maxlength="10"/>
                                        <!--<input name="dob" id="dob" type="text" 
                                        onfocus="(this.type='date')" onblur="if(!this.value) this.type='text'" placeholder="Date of Birth(MM/DD/YYYY)*" class="identity-input" />-->
                                        <label for="dob" class="identity-floating-label">Date of Birth(MM/DD/YYYY)*</label>
                                        <svg class="svg identity-addon-icon addon-icon" id="calendarIcon" width="40px" height="40px" aria-hidden="true" role="img"><use href="#svg__calendar" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__calendar"></use></svg>
                                        
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity-field floating-field">
                                        <input name="addressLine1" id="addressLine1" type="text" placeholder="none" class="identity-input" />
                                        <label for="addressLine1" class="identity-floating-label">Address Line 1*</label>
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity-field floating-field">
                                        <input name="addressLine2" id="addressLine2" type="text" placeholder="none" class="identity-input" />
                                        <label for="addressLine2" class="identity-floating-label">Address Line 2</label>
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field floating-field ">
                                        <input name="city" id="city" type="text" placeholder="none" class="identity-input" />
                                        <label for="city" class="identity-floating-label">City*</label>
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div  class="identity-field floating-field" >
                                        <input name="state" id="state" type="text" placeholder="none" class="identity-input" />
                                        <label for="state" class="identity-floating-label">State*</label>
                                        <select class="identity-input" name="state" id="state" style="display:none;">
                                            <option value="">State</option>
                                            <option>Texas</option>
                                            <option>New York</option>
                                        </select>
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field floating-field">
                                        <input name="zip" id="zip" type="text" placeholder="none" class="identity-input" onkeypress="return /[0-9]/i.test(event.key)" maxlength="5" />
                                        <label for="zip" class="identity-floating-label">Zip*</label>
                                        <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field floating-field">
                                        <input name="phone" id="phone" type="tel" placeholder="none" class="identity-input input" maxlength="14" onkeypress="return /[0-9]/i.test(event.key)" />
                                        <label for="phone" class="identity-floating-label">Phone*</label>
                                        <svg class="icon icon-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity-field floating-field">
                                        <input name="email" id="email" type="text" placeholder="none" class="identity-input" />
                                        <label for="email" class="identity-floating-label">Email</label>
                                        <svg class="icon icon-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                               <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                           </svg>
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity__row column-reverse-xs">
                                    <div class="identity__col-6">
                                        <div class="identity-field last">
                                        <button type="button" class="identity-btn identity-btn__secondary" id="btnCancel">Cancel</button>
                                        </div>
                                    </div>
                                    <div class="identity__col-6">
                                        <div class="identity-field">
                                            <button id="btn-continue" type="submit" class="identity-btn btn-continue">Continue                                            
                                              <span class="loading-icon">
                                                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                                  <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                                </svg>
                                              </span>
                                            </button>
                                        </div>
                                    </div>
                                    </div>                                                
                                </div>                                                                     
                                </div>                                            
                            </form>`
    );

    this.bindEvents();
  };

  getProfileDetails() {
    return request
      .get(`/Profile/${getSessionId()}`)
      .then(({ data }) => {
        var dob = "";
        if (data.data) {
          dob = data.data?.dob
            ? data.data.dob.replace(
                /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
                "$2/$3/$1"
              )
            : "";
        }
        return {
          ...data.data,
          dob,
        };
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fillInAddress(self) {
    // const self = this;
    // Get the place details from the autocomplete object.
    var place = self.autocomplete.getPlace();

    for (var code in self.componentForm) {
      let component = self.componentForm[code];
      if (getShadowRoot().querySelector("#" + component["id"])) {
        getShadowRoot().querySelector("#" + component["id"]).value = "";
      }
    }

    // Get each component of the address from the place details
    // and fill the corresponding field on the form.
    // console.log("place.address_components", place.address_components);
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      if (self.componentForm[addressType]) {
        let val =
            place.address_components[i][
              self.componentForm[addressType]["code"]
            ],
          component = self.componentForm[addressType];

        if (
          component &&
          component["id"] === "addressLine1" &&
          getShadowRoot().querySelector("#" + component["id"])
        ) {
          let value = getShadowRoot().querySelector(
            "#" + component["id"]
          ).value;
          getShadowRoot().querySelector("#c" + component["id"]).value += value
            ? " " + val
            : val;
        } else if (
          component &&
          getShadowRoot().querySelector("#" + component["id"])
        )
          getShadowRoot().querySelector("#" + component["id"]).value += val;
      }
    }
  }

  initAutocomplete() {
    const self = this;
    if ("google" in window && window.google.hasOwnProperty("maps")) {
      // Create the autocomplete object, restricting the search to geographical
      // location types.
      self.autocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ (
          getShadowRoot().querySelector("#addressLine1")
        ),
        {
          // types: ['geocode'],
          placeholder: "",
          componentRestrictions: { country: "us" },
        }
      );

      // When the user selects an address from the dropdown, populate the address
      // fields in the form.
      self.autocomplete.addListener("place_changed", () => {
        self.fillInAddress(self);
        if (self.validator) self.validator.validate();
      });
    }
  }

  bindEvents() {
    // this.initAutocomplete();
    const cancelButton = getShadowRoot().querySelector("#btnCancel");

    cancelButton.addEventListener("click",()=>{
      closeSession()
    })

    const personalInfoForm =
      getShadowRoot().querySelector(`#personalInfoContent`);

    personalInfoForm.addEventListener("submit", (event) => {
      event.preventDefault();

      const fields = {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        dob: {
          required: true,
        },
        addressLine1: {
          required: true,
        },
        addressLine2: {
          required: false,
        },
        city: {
          required: true,
        },
        state: {
          required: true,
        },
        zip: {
          required: true,
        },
        phone: {
          required: true,
        },
        email: {
          required: false,
        },
      };

      this.validator = new FormValidator(event.target, fields);

      let validData = this.validator.validate();
      if (validData.isValid) {
        // unmasking phone input
        validData.data.phone = validData.data.phone.replace(/[^\d\+]/g, "");
        validData.data.dob = validData.data.dob.replace(
          /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/,
          "$3-$1-$2"
        );
        setLoading(true);

        if (this.allowEdit === true) {
          request
            .put(`/Details/${getSessionId()}`, {
              ...validData.data,
              countryCode: "+1",
            })
            .then(({ data }) => {
              setSessionData({
                redirectBack: false,
                editPersonalInfo: false,
                personalInfoUpdated: true,
              });
              nextPrev(1);
            })
            .catch(({ response }) => {
              if (
                response?.data &&
                response.data?.errors &&
                response?.data?.errors?.REDIRECT_BACK
              ) {
                setSessionData({
                  redirectBack: true,
                  editPersonalInfo: false,
                  personalInfoUpdated: true,
                });
                nextPrev(1);
              } else setError(response);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          request
            .post(`/Details/${getSessionId()}`, {
              ...validData.data,
              countryCode: "+1",
            })
            .then(({ data }) => {
              setSessionData(data.data);
              nextPrev(1);
            })
            .catch(({ response }) => {
              setError(response);
              setLoading(false);
            })
            .finally(() => {
              // setLoading(false);
            });
        }
      }

    });

    const myInput = personalInfoForm.querySelector("#dob");

    myInput.addEventListener('input', function() {
      var inputValue = this.value.replace(/\D/g, ''); // Remove non-numeric characters
      var formattedValue = '';
  
      if (inputValue.length > 0) {
          formattedValue += inputValue.substring(0, 2); // Extract month
          if (inputValue.length >= 3) {
              formattedValue += '/' + inputValue.substring(2, 4); // Add dash and extract day
          }
          if (inputValue.length >= 5) {
              formattedValue += '/' + inputValue.substring(4, 8); // Add dash and extract year
          }
      }
  
      this.value = formattedValue;
  });
    const options = {
      allowInput:"true",
      enableTime: false,
      dateFormat: "m/d/Y",
      disableMobile: "true",
      disable: [
        function (date) {
          // return true to disable
          return new Date() < date;
        },
      ],
      appendTo : getShadowRoot(),
      onclose : ()=>{
      },
    };
    this.datePicker = flatpickr(myInput, options);

  if(isMobile()){
      this.datePicker.calendarContainer.addEventListener('touchstart', function(event) {
        event.stopPropagation();
      });
    }
  else{
      this.datePicker.calendarContainer.addEventListener('mousedown', function(event) {
      event.stopPropagation();
      });
    }
    
    const calendarIcon = personalInfoForm.querySelector("#calendarIcon");
    calendarIcon.addEventListener("click", function (e) {
      personalInfoForm.querySelector("#dob").focus();
    });

    personalInfoForm.querySelector("#dob").addEventListener('blur',function(event){
      //Check blur or tab event out of flatpickr. This is Patch.
      if(event?.relatedTarget?.className?.indexOf("identity")!==-1){ 
           let flatpickrinstance = personalInfoForm.querySelector("#dob")._flatpickr;
           flatpickrinstance.close();
       }
    });

    const handleKeyDownCallback = (e) => {
      var evt = e || window.event;
      var keyCode = evt.keyCode || evt.which;
      // let keyCode = e.keyCode;
      let val = e.target?.value;
      if (val)
        e.target.value = val
          .replace(/\D/g, "")
          .replace(/^0+/, "")
          .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, function (txt, f, s, t) {
            if (t) {
              return `(${f})-${s}-${t}`;
            } else if (s) {
              return `(${f})-${s}`;
            } else if (keyCode === 8 && f.length > 0 && f.length <= 3) {
              return `(${f}`;
            } else if (f) {
              return `(${f})`;
            }
          });
    };

    personalInfoForm
      .querySelector("#phone")
      .addEventListener("keydown", function (e) {
        var evt = e || window.event;
        var keyCode = evt.keyCode || evt.which;
        if (keyCode) handleKeyDownCallback(e);
        else setTimeout(() => handleKeyDownCallback(e), 100);
      });

    const sessionData = getSessionData();
    if (
      sessionData?.preFillInformation &&
      Object.values(sessionData.preFillInformation).length
    ) {
      this.personalInfoObj = sessionData?.preFillInformation;
      this.fillInputs();
    }
    if (sessionData?.profileDetails?.showFromUpdateInfo === true)
      this.fillInputs();
  }

  formatDate(inputDate) {
    if (!inputDate) return "";
    var date = new Date(inputDate + "T00:00:00");
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }
}

export default PersonalInfo;
